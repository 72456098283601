import React from "react"
import { useDispatch, useSelector } from "react-redux"
import ImmutablePropTypes from "react-immutable-proptypes"
import { fromJS } from "immutable"
import PropTypes from "prop-types"
import classNames from "classnames"
import Link from "highline/components/secure_link"
import Markdown from "highline/components/markdown"
import { toPromoModalFields } from "highline/utils/modal_helper"
import { contentfulComponentClicked } from "highline/redux/actions/contentful_actions"
import { shouldPromoTypeShowDiscount } from "highline/utils/promo_auto_apply_helper"

import { LOAD_STATUS } from "highline/utils/constants"
import TabGroup from "highline/components/tab_group"
import TabItem from "highline/components/tab_item"
import { getField } from "highline/utils/contentful/contentful_helper"
import styles from "highline/styles/components/pdp/summary.module.css"
import { pdpTabClicked } from "highline/redux/actions/product_detail_actions"

const Summary = ({
  className,
  isFinalSale,
  isGiftCard,
  isModal,
  isNewColor,
  isNewProduct,
  layout,
  shortDescription,
  name,
  onSale,
  originalPrice,
  price,
  promoCode,
  promoPrice,
  pdpNoveltyBadge,
  relatedProductLinks,
  url,
  slug,
}) => {
  const promo = useSelector((state) => state.getIn(["activePromotion", "promo"]))
  const promoType = useSelector((state) =>
    state.getIn(["activePromotion", "promo", "fields", "autoapplyPromoType"])
  )
  const promoTermsAndConditionsModal = toPromoModalFields(promo)
  const dispatch = useDispatch()
  const promotionExclusionLoadStatus = useSelector((state) => state.getIn(["promotion", "status"]))
  const shouldRenderPromoPreview =
    promotionExclusionLoadStatus === LOAD_STATUS.SUCCEEDED && promoPrice && promoCode && !isGiftCard

  const findDefaultTabItemForRelatedProducts = relatedProductLinks.find((item) => {
    const getItemSlugByUrl = ({ itemUrl = "" } = { url: "" }) => {
      try {
        if (!itemUrl) throw new Error("Invalud item url")
        return [itemUrl.split("products/")?.[1]?.split("?")[0].replace("/", ""), null]
      } catch (error) {
        return [null, error]
      }
    }
    const itemUrl = getField(item, "url")
    const [itemSlug, err] = getItemSlugByUrl({ itemUrl })

    // If the function fails, use the old way to select current tab
    return err ? itemUrl.includes(url) : itemSlug === slug
  })

  const noveltyBadgeFilter = pdpNoveltyBadge && pdpNoveltyBadge.toLowerCase() !== "icon status"
  const shouldDisplayNoveltyBadge =
    (noveltyBadgeFilter || isNewProduct || isNewColor) && !isFinalSale && !onSale

  const defaultTabForRelatedProducts = getField(findDefaultTabItemForRelatedProducts, "name")

  const shouldRenderDiscount =
    shouldRenderPromoPreview && shouldPromoTypeShowDiscount({ promoType, onSale, isFinalSale })
  const relatedProducts = (
    <div className={styles.relatedProductLinksContainer}>
      <TabGroup
        layout="sliding"
        className={styles.relatedProductLinksTabGroup}
        value={defaultTabForRelatedProducts}
        navItemClassName={styles.relatedProductNavItem}
        removeBtnTabIndex
      >
        {relatedProductLinks.map((relatedPdts, index) => {
          const productUrl = getField(relatedPdts, "url")

          return (
            <TabItem
              key={`${getField(relatedPdts, "name")}-${index}`}
              title={getField(relatedPdts, "name")}
              titleContent={
                <Link
                  href={productUrl}
                  onClick={() => dispatch(pdpTabClicked(slug, productUrl))}
                  aria-label={`Navigate to Related Product Details Page of ${getField(
                    relatedPdts,
                    "name"
                  )}`}
                  className={classNames(
                    styles.relatedProductLinksAnchor,
                    getField(relatedPdts, "name") === defaultTabForRelatedProducts &&
                      styles.relatedProductLinksAnchorSelected
                  )}
                >
                  {getField(relatedPdts, "name")}
                </Link>
              }
            ></TabItem>
          )
        })}
      </TabGroup>
    </div>
  )

  const promoPriceNumeric = promoPrice && Number(promoPrice.replace("$", ""))
  const truncatedPromoPrice = promoPriceNumeric && promoPriceNumeric.toFixed(2)
  const formattedPromoPrice = Number.isInteger(promoPriceNumeric)
    ? promoPrice
    : truncatedPromoPrice % 1
      ? `$${truncatedPromoPrice}`
      : `$${parseInt(truncatedPromoPrice)}`

  return (
    <div
      className={classNames(
        "component",
        "summary-component",
        styles.component,
        styles[layout],
        className,
        isModal ? styles.bottomPadding : ""
      )}
    >
      {shouldDisplayNoveltyBadge && (
        <div className={classNames(styles.pdpBadges, pdpNoveltyBadge ? styles.noveltyBadge : null)}>
          {noveltyBadgeFilter ? pdpNoveltyBadge : isNewProduct ? "New" : "New Color"}
        </div>
      )}
      <div className={styles.productHeading}>
        {layout === "long" && <h1 className={styles.name}>{name}</h1>}

        {layout === "short" && <div className={styles.name}>{name}</div>}

        {!onSale && (
          <div className={styles.price}>
            <span className={styles.fullPrice}>{price}</span>
            {isFinalSale && <span className={styles.finalSale}>Final Sale</span>}
          </div>
        )}

        {onSale && (
          <div className={styles.onSaleContainer}>
            <div className={styles.price}>
              <span className={styles.strikethroughPrice}>{originalPrice}</span>
              <span className={styles.salePrice}>{price}</span>
            </div>
            {isFinalSale && (
              <span className={classNames(styles.finalSale, styles.finalSaleOnSale)}>
                Final Sale
              </span>
            )}
          </div>
        )}
        <div id="attn-inline-price-trigger" />
        {shouldRenderDiscount && (
          <div className={styles.promoPriceContainer}>
            <div className={styles.price}>
              <span className={styles.salePrice}>
                <span className={styles.promoPrice}>{formattedPromoPrice}</span>
                <span> with code </span>
                <button
                  className={styles.promoCodeButton}
                  aria-label="Open Promotion Code Modal"
                  onClick={() =>
                    dispatch(
                      contentfulComponentClicked(
                        "modal",
                        promoTermsAndConditionsModal,
                        "Promotion Terms and Conditions Modal"
                      )
                    )
                  }
                >
                  {promoCode}
                </button>
              </span>
            </div>
          </div>
        )}
      </div>
      {relatedProductLinks.size > 0 && relatedProducts}
      {shortDescription && <Markdown className={styles.description} source={shortDescription} />}
    </div>
  )
}

Summary.propTypes = {
  className: PropTypes.string,
  isFinalSale: PropTypes.bool,
  isGiftCard: PropTypes.bool,
  isModal: PropTypes.bool,
  isNewColor: PropTypes.bool,
  isNewProduct: PropTypes.bool,
  layout: PropTypes.oneOf(["long", "short"]),
  name: PropTypes.string,
  onSale: PropTypes.bool,
  originalPrice: PropTypes.string,
  pdpNoveltyBadge: PropTypes.string,
  price: PropTypes.string,
  promoCode: PropTypes.string,
  promoPrice: PropTypes.string,
  shortDescription: PropTypes.string,
  relatedProductLinks: ImmutablePropTypes.list,
  url: PropTypes.string,
  slug: PropTypes.string,
}

Summary.defaultProps = {
  isGiftCard: false,
  isModal: false,
  isNewColor: false,
  isNewProduct: false,
  layout: "long",
  name: "",
  onSale: false,
  pdpNoveltyBadge: "",
  price: "",
  promoCode: "",
  promoPrice: "",
  relatedProductLinks: fromJS([]),
  url: "",
  slug: "",
}

export default Summary
